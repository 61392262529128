import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { db } from '../../firebase.config'
import { Timestamp , collection , addDoc} from 'firebase/firestore'
import ModalComponent from './Components/ModalComponent';

function Razorpay() {
    const[formData, setFormData] = useState({
        name:'',
        amount:'',
        projectName:'',
        email:'',
        country:'',
        address:'',
        createdOn:Timestamp.now().toDate()
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
const payNow=()=>{
const {name , amount, email , projectName, country , address} = formData
    if(name==null || amount==null || email==null || projectName==null || country==null || address==null){
toast("All fileds are required")
    }
    else{
        const paymentRef = collection(db , 'komal_portfolio_payment');
                addDoc(paymentRef , {
                    name:name,
                    amount:amount,
                    email:email,
                    projectName:projectName,
                    country:country,
                    address:address
                }).then(()=>{
                    toast("article added succesfully", {
                        type:'success'
                    });
                    // setProgress(0);
                    // setLoading(false)
                }).catch(err=>{
                    toast("error adding article" , {type:'error'})
                    console.log(err)
                })
    }

}

var options = {
    "key": "rzp_test_xdh81RQ1zMNJb0", // Enter the Key ID generated from the Dashboard
    "amount": parseInt((formData?.amount) * 100), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "Komal Sharma", //your business name
    "description": formData?.projectName,
    // "image": "https://example.com/your_logo",
    "order_id": formData?.name , //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": function (response){
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);

        const paymentID = response.razorpay_payment_id;
        const {name , amount, email , projectName, country , address} = formData
        const orderInfo = {
            
            name:name,
            amount:amount,
            email:email,
            projectName:projectName,
            country:country,
            address:address,
            paymentID : paymentID
        };
try{
    const paymentRef = collection(db , 'komal_portfolio_payment');
    addDoc(paymentRef, orderInfo)

}
catch(err){
console.log(err);
}
                
    },
    "theme": {
        "color": "#3399cc"
    }
};

const handleSubmit=(e)=>{
    e.preventDefault();
    console.log(formData)
    alert(formData)
}
    
  return (
    <ModalComponent formData={formData} handleChange={handleChange} handleSubmit={handleSubmit}/>
  )
}

export default Razorpay