import { Component, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsTelephone } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import { BiMap } from "react-icons/bi";
import { toast } from 'react-toastify';
import CheckoutForm from "./CheckoutForm";
import { FaCreditCard } from "react-icons/fa";
import ModalComponent from "./Components/ModalComponent";
import Razorpay from "./Razorpay";



const Footer =()=> {

  
  const [cardDisplay , setCardDisplay] = useState(false)
    // const notify = () => toast("Wow so easy !");  
    return (
      <>
        <footer className="footer headding" id="Contact">
          <div className="footer-head">
            <Container>
              <div className="py-5 text-center">
                { <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448193.95104313316!2d76.76356386805335!3d28.644287350488735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1704184299076!5m2!1sen!2sin"
                  width="100% auto"
                  height="350px"
                  frameBorder="0"
                  title="Iframe"
                  aria-hidden="false"
                  tabIndex="0"
                />
                
  }
              </div>

              <Row>
                <Col lg={6} md={12}>
                  <div className="f-left">
                    <div className="f-content">
                      <h1 className="F-contact">Contact Us</h1>
                      <h1 className="f-text">Let's Discuss Your project</h1>
                    </div>
                    <div className="">
                      <h6 className="f-icon">
                        <i className="icon">
                          <BsTelephone size={20} />
                        </i>
                        <span>+91-7042031184</span>
                      </h6>
                      <h6 className="f-icon">
                        <i className="icon">
                          <BsEnvelope size={20} />
                        </i>
                        <span>komalkashvisharma@gmail.com</span>
                      </h6>
                      <h6 className="f-icon">
                        <i className="icon">
                          <BiMap size={20} />
                        </i>
                        <span>New Delhi, India</span>
                      </h6>
                      {/* <h6 className="f-icon" style={{ display:'flex'}}>
                        <i className="icon" style={{height:'2.7rem', height:'2.7rem', borderRadius:'50%'}}>
                          <FaCreditCard size={20} onClick={()=>setCardDisplay(!cardDisplay)}/>
                        </i>
                        { cardDisplay && <CheckoutForm setCardDisplay={setCardDisplay}/>}
                      </h6> */}
                      <h6>
                        <Razorpay/>
                      </h6>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={12} className="f-end">
                
                   <Row>
              <Col xl={12} md={12} lg={12} sm={12}>
                <div>
               
                <form className="row g-3 needs-validation formContactus">
                  <div className="col-md-6  col-sm-6 col-6">
                    <input
                      type="text"
                      className="form-control inputbox "
                      id="validationCustom01"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="col-md-6  col-sm-6 col-6">
                    <input
                      type="text"
                      className="form-control inputbox"
                      id="validationCustom01"
                      placeholder="Phone"
                      required
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <input
                      type="text"
                      className="form-control inputbox"
                      id="validationCustom02"
                      placeholder="E-mail"
                      required
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                  <input
                      type="text"
                      className="form-control inputbox "
                      id="validationCustom01"
                      placeholder="Subject"
                      required
                    />
                    </div>
                 
                  <div className="col-md-12">
                    <textarea
                      type="text"
                      className="form-control inputboxmessage inputbox"
                      id="validationCustom03"
                      placeholder="Meassage*"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a Send Meassage.
                    </div>
                  </div>

                  <div className="col-12 ">
                    <button className="send-btn button float-start" onClick={() => toast("Thank you for contacting!")}>Send Message</button>
                  </div>
                </form>
                </div>
              </Col>
            </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </footer>
        <div className="f-link">Copyright © 2021. All Rights Reserved.</div>
      </>
    );
  }


export default Footer;