import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

const Experience = () => {
  return (
    <>
      <div id="Experience" className="exp-main headding">
                    <div className="exp ">
                        <h1 className="text-center title">Experience</h1>
                    </div>
                    <Container >
                        <Row >
                            <Col lg={6} md={6} sm={6}>
                                <div className="exp-card" data-aos="flip-left">
                                    <Card className="exp-block">
                                        <Card.Body className="exp-body">
                                            <Card.Title className="exp-title">Head - Reactjs - Front- End developer
                                                <p>
                                                April 2023 - till Date | Full Time | Sortyout.
                                                </p>
                                            </Card.Title>
                                            <hr />
                                            <Card.Text className="exp-text">
                                            <ul>
                                            <li>Executing full lifecycle software development</li>
  <li>Develop software verification plans and quality assurance procedures</li>
  <li>Document and maintain software functionality</li>
  <li>Troubleshoot, debug, and upgrade existing systems</li>
  <li>Deploy programs and evaluate user feedback</li>
  <li>Comply with project plans and industry standards</li>
  <li>Ensure software is updated with the latest features</li>
</ul>


                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="exp-card" data-aos="flip-left">
                                    <Card className="exp-block">
                                        <Card.Body className="exp-body">
                                            <Card.Title className="exp-title">Software Developer - Reactjs

                                                <p>
                                                Nov 2020 – March 2023 | Full Time | Dynamics Monk Pvt Ltd
                                                </p>
                                            </Card.Title>
                                            <hr />
                                            <Card.Text className="exp-text">
                                            <ul>
  <li>Executing full lifecycle software development</li>
  <li>Develop software verification plans and quality assurance procedures</li>
  <li>Document and maintain software functionality</li>
  <li>Troubleshoot, debug, and upgrade existing systems</li>
  <li>Deploy programs and evaluate user feedback</li>
  <li>Comply with project plans and industry standards</li>
  <li>Ensure software is updated with the latest features</li>
</ul>

                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="exp-card" data-aos="flip-left">
                                    <Card className="exp-block">
                                        <Card.Body className="exp-body">
                                            <Card.Title className="exp-title">Reactjs - Front- End developer
                                                <p>
                                                April 2018 - October 2020 | Full Time | N.S Technologies Pvt Ltd
                                                </p>
                                            </Card.Title>
                                            <hr />
                                            <Card.Text className="exp-text">
                                            <ul>
                                            <li>Executing full lifecycle software development</li>
  <li>Develop software verification plans and quality assurance procedures</li>
  <li>Document and maintain software functionality</li>
  <li>Troubleshoot, debug, and upgrade existing systems</li>
  <li>Deploy programs and evaluate user feedback</li>
  <li>Comply with project plans and industry standards</li>
  <li>Ensure software is updated with the latest features</li>
</ul>

                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}    >
                                <div className="exp-card" data-aos="flip-left">
                                    <Card className="exp-block">
                                        <Card.Body className="exp-body">
                                            <Card.Title className="exp-title">Free Lancer
                                                <p>
                                                    2017-2018 | Part Time | InHouse
                                                </p>
                                            </Card.Title>
                                            <hr />
                                            <Card.Text className="exp-text">
                                                <ul>
                                            <li>Executing full lifecycle software development</li>
  <li>Develop software verification plans and quality assurance procedures</li>
  <li>Document and maintain software functionality</li>
  <li>Troubleshoot, debug, and upgrade existing systems</li>
  <li>Deploy programs and evaluate user feedback</li>
  <li>Comply with project plans and industry standards</li>
  <li>Ensure software is updated with the latest features</li>
  </ul>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
    </>
  )
}

export default Experience