import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import I1 from "../../../assets/Images/Services/I1.png";
import I2 from "../../../assets/Images/Services/I2.png";
import I3 from "../../../assets/Images/Services/I3.png";
import I4 from "../../../assets/Images/Services/I4.png";
import I5 from "../../../assets/Images/Services/I5.png";
import I6 from "../../../assets/Images/Services/I6.png";
import Aos from "aos";
import "aos/dist/aos.css";
const Service = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="main-service headding" id="Service">
        <Container>
          <div className="service ">
            <h1 className="text-center service-title">My Services</h1>
            <Row className="g-4">
              <Col
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="service-block"
              >
                <Card
                  className="service-card"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <Card.Body>
                    <Card.Title>
                      <img
                        alt="serviceImage"
                        className="img-fluid mx-auto"
                        src={I1}
                      />
                    </Card.Title>
                    <Card.Subtitle className="cardtitle ">
                      Web Devlopment
                    </Card.Subtitle>
                    <Card.Text className="service-text">
                      In web development, I bring expertise in creating robust
                      and user-centric digital experiences through a combination
                      of frontend and backend technologies, ensuring seamless
                      functionality and optimal performance.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="service-block"
              >
                <Card
                  className="service-card"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <Card.Body>
                    <Card.Title>
                      <img
                        alt="serviceImage"
                        className="img-fluid mx-auto"
                        src={I4}
                      />
                    </Card.Title>
                    <Card.Subtitle className="cardtitle">
                      Web Design
                    </Card.Subtitle>
                    <Card.Text className="service-text">
                      In web design, my focus is on crafting visually appealing
                      and intuitive interfaces that enhance user engagement,
                      employing a combination of aesthetic principles, user
                      experience considerations, 
                      {/* and the latest design trends to */}
                      create 
                      {/* impactful and  */}
                       user-friendly websites.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="service-block"
              >
                <Card
                  className="service-card"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <Card.Body>
                    <Card.Title>
                      <img
                        alt="serviceImage"
                        className="img-fluid mx-auto"
                        src={I2}
                      />
                    </Card.Title>
                    <Card.Subtitle className="cardtitle">
                      Tech Stack
                    </Card.Subtitle>
                    <Card.Text className="service-text">
                      My proficiency spans a versatile tech stack, including
                      HTML5, CSS3, and JavaScript for building interactive and
                      responsive user interfaces. I leverage the power of React
                      to create dynamic front-end experiences
                       {/* complemented by */}
                      {/* Bootstrap 5 for streamlined design.  */}
                      {/* In the backend, I am
                      adept at using Node.js and MongoDB, ensuring robust
                      server-side functionality and efficient database
                      management. */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="service-block"
              >
                <Card
                  className="service-card"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <Card.Body>
                    <Card.Title>
                      <img
                        alt="serviceImage"
                        className="img-fluid mx-auto"
                        src={I5}
                      />
                    </Card.Title>
                    <Card.Subtitle className="cardtitle">
                      Responsive apps
                    </Card.Subtitle>
                    <Card.Text className="service-text">
                      In crafting responsive designs, I prioritize fluid layouts
                      and flexible elements, utilizing HTML5, CSS3, and media
                      queries to ensure seamless adaptability across various
                      devices. I create user interfaces 
                      {/* that dynamically adjust */}
                      to different screen sizes, 
                      {/* providing an optimal viewing
                      experience for users on desktops, tablets, and mobile
                      devices. */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="service-block"
              >
                <Card
                  className="service-card"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <Card.Body>
                    <Card.Title>
                      <img
                        alt="serviceImage"
                        className="img-fluid mx-auto"
                        src={I3}
                      />
                    </Card.Title>
                    <Card.Subtitle className="cardtitle">
                      SEO & Meta Tags
                    </Card.Subtitle>
                    <Card.Text className="service-text">
                      Implementing effective meta tags is crucial for optimizing
                      web content.Leveraging HTML meta tags,I enhance SEO by
                      strategically incorporating meta titles,descriptions, and
                      keywords, providing search engines with conciseinfo.
                      {/* information about the webpage's content. Additionally, I
                      optimize social media sharing with Open Graph and Twitter
                      Card meta tags, ensuring visually appealing previews when
                      shared on platforms like Facebook and Twitter. */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="service-block"
              >
                <Card
                  className="service-card"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <Card.Body>
                    <Card.Title>
                      <img
                        alt="serviceImage"
                        className="img-fluid mx-auto"
                        src={I6}
                      />
                    </Card.Title>
                    <Card.Subtitle className="cardtitle">
                      A+ Grade Web Apps
                    </Card.Subtitle>
                    <Card.Text className="service-text">
                      A+ grade web applications epitomize excellence, combining
                      seamless user experiences with efficient functionality.
                      Leveraging cutting-edge technologies such as React.js for
                      dynamic interfaces, Node.js 
                      {/* for robust server-side
                      operations,  */}
                      and MongoDB 
                      {/* for scalable database management,
                      I strive to create web apps that not only meet but exceed
                      user expectations. Adhering to best practices in
                      responsive design, performance optimization, and security,
                      these applications deliver top-tier performance, ensuring
                      a superior and reliable digital experience for users
                      across platforms. */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Service;
