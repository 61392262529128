import { ToastContainer } from 'react-toastify';
import './App.css';
import Index from './pages/Index';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div id='App'>
    <Index/>
    <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* Same as */}
{/* <ToastContainer /> */}
    </div>
  );
}

export default App;
