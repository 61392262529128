import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import ks from "../../../assets/Images/About/ks.jpeg";

const About = () => {
  return (
    <>
      <div className="container" id="About">
        <div className="about headding " data-aos="fade-right">
          <Row className="about-main">
            <Col md={5}>
              <div className="about-img ">
                <Image
                  data-aos="flip-right"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                  className="d-block img-fluid mx-auto rounded-circle"
                  src={ks}
                />
                <div className="text-center About-name">
                  <h6>Komal Sharma</h6>
                  <p>Meta-React Js Developer</p>
                </div>
              </div>
            </Col>
            <Col md={7} className="abouttextbox">
              <div>
                <h1 className="about-title">About Me</h1>
                <p className="about-subtitle">
                Experienced Software engineer with a strong background in developing Full Stack Web Applications. Experienced all stages of the Software development life cycle for dynamic web projects. 
                </p>

                <p className="about-subtitle">
                Well-versed in numerous programming languages including HTML5, JavaScript, CSS3, React-Js, Wordpress, Shopify, Bootstrap5, MongoDB ,Node-Js, Scss, Core Java etc. Hard working and multitasking.
                </p>
              </div>
              <div className="about-psl-info">
                <h1 className="about-sub-info">Personal Info</h1>
                <Row>
                  <Col>
                    <div className="about-list">
                      <span className="perName1">Name : </span>
                      <span className="perName2">Komal Sharma</span>
                    </div>

                    <div className="about-list">
                      <span className="perName1">Sector : </span>
                      <span className="perName2">React Js Developer</span>
                    </div>
                    <div className="about-list">
                      <span className="perName1">Nationality : </span>
                      <span className="perName2">India</span>
                    </div>
                    <div className="about-list">
                      <span className="perName1">Freelance : </span>
                      <span className="perName2">Available</span>
                    </div>
                  </Col>
                  <Col>
                    <div className=" about-list ">
                      <span className="perName1"> Address : </span>
                      <span className="perName2"> Delhi, India</span>
                    </div>
                    <div className=" about-list">
                      <span className="perName1">Phone : </span>
                      <span className="perName2">0091-7042031184</span>
                    </div>
                    <div className=" about-list">
                      <span className="perName1">Skype : </span>
                      <span className="perName2">live:.cid.ce4e5bce8c508e62</span>
                    </div>
                    <div className=" about-list">
                      <span className="perName1">E-mail : </span>
                      <span className="perName2">komalkashvisharma@gmail.com</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <button className="BtnView">
                <a rel='noreferrer' href="https://drive.google.com/file/d/1nDWYoA8Z9vvK6NScLn1Jm7Z45_7-sF81/view?usp=drive_link" 
                target="_blank" style={{color:'inherit', textDecoration:'none'}}>
                  Download CV
                </a>
                </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default About;
