import React from 'react'
import ReactWhatsapp from 'react-whatsapp';
import { FaWhatsapp } from "react-icons/fa";

function Whatsapp() {
  return (
    <div style={{position:'fixed', bottom:'1rem', left:'1rem',zIndex:'20', backgroundColor:'transparent'}}>
        
        <ReactWhatsapp number="918595218711" message="Hello,How May I Assist You?" style={{border:'none',backgroundColor:'transparent'}} target='_blank'>
        <FaWhatsapp style={{color:'green',fontSize:'2rem' }}/>
        </ReactWhatsapp>
    </div>
  )
}

export default Whatsapp