import React from "react";
import { FaCreditCard } from "react-icons/fa";
// import ReactDOM from 'react-dom';
import Modal from "react-modal";
// Modal.setAppElement('#App');

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "1rem",
    borderRadius: "20px",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

const ModalComponent = ({formData, handleChange, handleSubmit}) => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    Modal.setAppElement("#App");
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <div
        className="f-icon d-flex align-items-center justify-content-space-between "
        style={{ color: "grey" }}
        onClick={openModal}
      >
        <i className="icon" style={{ height: "2.7rem", borderRadius: "50%" }}>
          <FaCreditCard size={20} />
        </i>
        &nbsp; Make Payment
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 style={{ color: "grey" }}>Payment Detail Form</h2>

        {/* <div style={{marginBottom:'1rem'}}></div> */}
        <form style={{ display: "inline-block", width: "100%" }} onSubmit={handleSubmit}>
          <div style={{ width: "100%", marginTop: "0.5rem" }}>
            <input
            type="text"
            name="name"
              value={formData?.name}
              onChange={handleChange}
              placeholder="Name"
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ width: "100%", marginTop: "0.5rem" }}>
            <input style={{ width: "100%" }}
            type="text"
            onChange={handleChange} 
            name="amount"
            value={formData?.amount}
            placeholder="Amount" />
          </div>
          <div style={{ width: "100%", marginTop: "0.5rem" }}>
            <input style={{ width: "100%" }}
            type="text"
            name="email"
            onChange={handleChange} 
            value={formData?.email}
            placeholder="Email ID" />
          </div>
          <div style={{ width: "100%", marginTop: "0.5rem" }}>
            {" "}
            <input style={{ width: "100%" }}
            type="text"
            name="projectName"
            onChange={handleChange} 
            value={formData?.projectName}
            placeholder="Project Name" />
          </div>
          <div style={{ width: "100%", marginTop: "0.5rem" }}>
            <input style={{ width: "100%" }}
            type="text"
            name="address"
            onChange={handleChange} 
            value={formData?.address}
            placeholder="Address" />
          </div>
          <div
            style={{
              width: "100%",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            {" "}
            <input style={{ width: "100%" }}
            type="text"
            name="country"
            onChange={handleChange} 
            value={formData?.country}
            placeholder="Country" />
          </div>
          <button
          type="submit"
            style={{
              color: "white",
              backgroundColor: "dodgerblue",
              padding: "0.4rem",
              borderRadius: "5px",
              border: "none",
              minWidth: "6rem",
            }}
          >
            Submit
          </button>
          <button
            style={{
              color: "white",
              backgroundColor: "red",
              padding: "0.4rem",
              marginLeft: "0.5rem",
              borderRadius: "5px",
              border: "none",
              minWidth: "6rem",
            }}
            onClick={closeModal}
          >
            Cancel
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default ModalComponent;
