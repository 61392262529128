import img1 from '../../../assets/Images/Portfolio/img1.png'
import img2 from '../../../assets/Images/Portfolio/img2.png'
import img3 from '../../../assets/Images/Portfolio/img3.png'
import img4 from '../../../assets/Images/Portfolio/img4.png'
import img5 from '../../../assets/Images/Portfolio/img5.png'
import img6 from '../../../assets/Images/Portfolio/img6.png'
import one from '../../../assets/Images/Portfolio/one.jpg'
import two from '../../../assets/Images/Portfolio/two.jpg'
import three from '../../../assets/Images/Portfolio/three.jpg'
import four from '../../../assets/Images/Portfolio/four.jpg'
import five from '../../../assets/Images/Portfolio/five.jpg'
import six from '../../../assets/Images/Portfolio/six.jpg'
import seven from '../../../assets/Images/Portfolio/seven.jpg'
import eight from '../../../assets/Images/Portfolio/eight.jpg'
import nine from '../../../assets/Images/Portfolio/nine.jpg'
import ten from '../../../assets/Images/Portfolio/ten.jpg'
import eleven from '../../../assets/Images/Portfolio/eleven.jpg'
import twelve from '../../../assets/Images/Portfolio/twelve.jpg'
import thirteen from '../../../assets/Images/Portfolio/thirteen.jpg'
import fourteen from '../../../assets/Images/Portfolio/fourteen.png'
import fifteen from '../../../assets/Images/Portfolio/fifteen.png'
import sixteen from '../../../assets/Images/Portfolio/sixteen.jpeg'
import seventeen from '../../../assets/Images/Portfolio/seventeen.jpeg'
import eighteen from '../../../assets/Images/Portfolio/eighteen.jpeg'
import nineteen from '../../../assets/Images/Portfolio/nineteen.jpeg'
import twentyone from '../../../assets/Images/Portfolio/twentyone.jpeg'
import twentytwo from '../../../assets/Images/Portfolio/twentytwo.jpeg'

export const PortfolioImg = [
    {
        id: 1,
        image: one,
        category: "Portfolios"
        
    },
    {
        id: 2,
        image: two,
        category: "Portfolios"
       
    },
    {
        id: 3,
        image: three,
        category: "E-commerces"
        
    },
    {
        id: 4,
        image: four,
        category: "Portfolios"
        
    },
    {
        id: 5,
        image: five,
        category: "E-commerces"
       
    },
    {
        id: 6,
        image: six,
        category: "Medical"
        
    },
    {
        id: 7,
        image: seven,
        category: "Medical"
        
    },
    {
        id: 8,
        image: eight,
        category: "E-commerces"
        
    },
    {
        id: 9,
        image: nine,
        category: "Art & Fasion"
        
    },
    {
        id: 10,
        image: ten,
        category: "Medical"
        
    },
    {
        id: 11,
        image: eleven,
        category: "Art & Fasion"
        
    },
    {
        id: 12,
        image: twelve,
        category: "Medical"
        
    },
    {
        id: 13,
        image: thirteen,
        category: "Portfolios"
        
    },
    {
        id: 14,
        image: fourteen,
        category: "E-commerces"
    },
    {
        id: 15,
        image: fifteen,
        category: "Art & Fasion"
        
    },
    {
        id: 16,
        image: sixteen,
        category: "Portfolios"
        
    },
    {
        id: 17,
        image: seventeen,
        category: "Medical"
        
    },
    {
        id: 18,
        image: eighteen,
        category: "Portfolios"
        
    },
    {
        id: 19,
        image: nineteen,
        category: "Art & Fasion"
        
    },
    {
        id: 21,
        image: twentyone,
        category: "E-commerces"
        
    },
    {
        id: 22,
        image: twentytwo,
        category: "Portfolios"
        
    },


]