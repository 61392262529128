// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIM1sdMgOoQV8MCo0wWPq4GGpGMW7avEA",
  authDomain: "komal-portfolio-d5148.firebaseapp.com",
  projectId: "komal-portfolio-d5148",
  storageBucket: "komal-portfolio-d5148.appspot.com",
  messagingSenderId: "585918720367",
  appId: "1:585918720367:web:bdce1c540cce298f71209a",
  measurementId: "G-PPDBFLF630"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app)