import React, { useEffect } from "react";
import Banner from "../../../assets/Images/Banner/bnr-ks.png";
import Aos from "aos";
import "aos/dist/aos.css";
const HeaderBanner = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const handleButtonClick = () => {
    const targetElement = document.getElementById('Service');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <div id="Home">
        <div className="banner-img">
          <span className=" ">
            <div className="" 
            // style={{ height:'380px'}}
            >
              
              <img
                src={Banner}
                className=" img-fluid  mx-auto width: 100% "
                alt="Banner"
                // style={{width:'100%', height:'500px'}}
              /> 
            </div>
          </span>
          <div className="img-bnr-text ">
            <div>
              <h5 data-aos="fade-down">Lead React-Js Devloper</h5>

              <h1 data-aos="flip-down">Komal Sharma</h1>
              <p data-aos="fade-up">
              Experienced Software engineer with a strong background in developing Full Stack Web Applications.
                <br />
                Experienced all stages of the Software development life cycle for dynamic web projects. 
                <br />
                HTML5, JavaScript, CSS3, React-Js, Wordpress, Shopify, Bootstrap5, MongoDB, Node-Js, Scss, Core Java etc.
              </p>
              <button data-aos="fade-up" className="BtnView" onClick={handleButtonClick}>
                VIEW MORE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderBanner;
