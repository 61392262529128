import React from "react";
import Slider from "react-slick";
import CR1 from "../../../assets/Images/CustomerReview/CR1.png";
import CR2 from "../../../assets/Images/CustomerReview/CR2.png";
import CR3 from "../../../assets/Images/CustomerReview/CR3.png";

import { Container, Row, Col } from "react-bootstrap";
const CustomerReview = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 2500,
    dots: true,
    aroow: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="main-review headding" id="Blog">
        <Container>
          <div className=" textblock">
            <Slider {...settings}>
              <div>
                <Row className="customerReview">
                  <Col md={2} xs={12}>
                    <img
                      className="img-fluid mx-auto img-circle img--size"
                      src={CR1}
                      alt="Review"
                    />
                  </Col>

                  <Col md={10} xs={12} className="customerP">
                    <p>
                    The software developed by her exceeded my expectations. It's robust, user-friendly, and precisely meets our requirements. The attention to detail and commitment to quality are commendable. A fantastic experience overall.
                    </p>
                    <div className="d-inline-block">
                      <span className="customerspan1">Dave liu </span>
                      <span className="customerspan2"> - Customer Service Manager,</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row className="customerReview">
                  <Col md={2} xs={12}>
                    <img
                      className="img-fluid mx-auto img-circle  img--size"
                      src={CR2}
                      alt="Review"
                    />
                  </Col>

                  <Col md={10} xs={12} className="customerP">
                    <p>
                    I faced some issues with my existing system, and she quickly identified the root causes and provided effective solutions. Her troubleshooting skills are impressive. I appreciate their prompt and reliable support.
                    </p>
                    <div className="d-inline-block">
                      <span className="customerspan1"> Maria Nakeshka </span>
                      <span className="customerspan2"> - Marketing Expert</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row className="customerReview">
                  <Col md={2} xs={12}>
                    <img
                      className="img-fluid mx-auto img-circle  img--size"
                      src={CR3}
                      alt="Review"
                    />
                  </Col>

                  <Col md={10} xs={12} className="customerP">
                    <p>
                    I recently worked with her, and I am extremely satisfied with her services. She was professional, and she delivered the project on time. The communication throughout the process was excellent. I highly recommend her!
                    </p>
                    <div className="d-inline-block">
                      <span className="customerspan1">Martin Thompson </span>
                      <span className="customerspan2"> - Solution Architect</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Slider>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CustomerReview;
